import { Component } from 'preact';
import { route } from 'preact-router';
import style from './style';
import items from '../items/items.json';

export default class Item extends Component {
  state = {item: {}, text: '', lang: this.props.lang === 'en' ? 'en' : 'fi'};

  queryItem(query, lang) {
    let { item } = this.state;
    let possItem = items.items[lang].find(i => i.id === query);
    const invalidText = lang === 'en' ? 'Invalid ID' : 'Virheellinen haku';
    item = possItem ? possItem : {name: '', id: '', desc: invalidText, imgUrl: ''}
    if (query === '') item.desc = '';
    this.setState({ item, text: '' });
  }

  redirectToItem(query) {
    route(`/${this.state.lang}/${query}`, true);
    this.queryItem(query, this.state.lang);
  }

  handleInputChange = e => {
      this.setState({ text: e.target.value });
      if (e.target.value.length === 6) {
        this.redirectToItem(e.target.value);
        e.target.blur();
      }
  };

	componentDidMount() {
    const searchId = this.props.id || '';
    const lang = this.props.lang === 'en' ? 'en' : 'fi';
    this.queryItem(searchId, lang);
  }
  
  switchLanguage = () => {
    const { item, lang } = this.state;
    const langSwap = lang === 'fi' ? 'en' : 'fi';
    const idOrEmpty = item.id || '';
    this.setState({ lang: langSwap });
    route(`/${langSwap}/${idOrEmpty}`, true);
    this.queryItem(idOrEmpty, langSwap);
  }

	render({ }, { item, text, lang }) {
		return (
      <div>
      <header class={style.header}>
      <div class={style.header__flex1}><a class={style.header__lang} href="" onClick={this.switchLanguage}>{lang === 'fi' ? 'EN' : 'FI'}</a></div>
      <div class={style.header__flex2}><a href="https://www.polyteekkarimuseo.fi/"><img src={'../assets/museo.png'} /></a></div>
      <div class={style.header__flex3}><a class={style.header__about} href="https://oispa.sill.is">??</a></div>
      </header>
      <div class={style.itemPage}>
        <div class={style.itemSearch}>
          <input class={style.newinput} maxlength='6' placeholder={item.id || this.props.id ? `${item.id || this.props.id}` : '012345'} type="number" value={text} onInput={this.handleInputChange} pattern="[0-9]*" />
        </div>
        <div class={style.itemResult}>
          <h1 class={style.itemResult__name}>{item.name}</h1>
          <p class={style.itemResult__desc}>{item.desc}</p>
          <p class={style.itemResult__image}><img src={item.imgUrl} /></p>
        </div>
			</div>
      </div>
		);
	}
}
